// @flow

import "./polyfills";
import React from "react";
import { configure } from "mobx";
import { Provider } from "mobx-react";

import "./src/styles/normalize.scss";
import "./src/styles/global.scss";
import { createStores } from "./src/stores/create-store";
import { Helmet } from "react-helmet/es/Helmet";

import Layout from "./src/containers/layout";

configure({ enforceActions: "always" });

const rootStore = createStores(true);

export const wrapPageElement = ({ element, props }: any) => (
  <Provider {...rootStore}>
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-166299382-1"
        />
        <script
          async
          defer
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCe822ASobIjlToV9olk99drv2sS_AhT80&libraries=places"
        />
        <script
          src="https://api.iheartjane.com/v1/brand_partners/361/embed.js"
          async
          defer
        />
      </Helmet>
      <Layout {...props} key={`LAYOUT`}>
        {element}
      </Layout>
    </>
  </Provider>
);
