// @flow

import { STORE_UI, STORE_JSON, STORE_CART } from '../constants/stores';
import JsonStore from './json-store'
import UiStore from './ui-store';
import CartStore from './cart-store';
import forEach from 'lodash/forEach';

export function createStores(initForBrowserUse: boolean=false) {

  const jsonStore = new JsonStore();
  const uiStore = new UiStore();
  const cartStore = new CartStore();

  return forEach({
    [STORE_JSON]: jsonStore,
    [STORE_UI]: uiStore,
    [STORE_CART]: cartStore,
  }, (store: any) => {
    if (initForBrowserUse && store.initForBrowserUse) store.initForBrowserUse();
  });
}
