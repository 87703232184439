// @flow

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { observer } from "mobx-react";
import styles from "./style.module.scss";
import * as classNames from "classnames";
import { Link } from "gatsby";

import { JSON_NAV } from "../../constants/json";
import ui from "../../stores/ui-store";
import json from "../../stores/json-store";

type NavigationProps = {
  ui: ui,
  json: json,
};

function DataWrapper(props: {
  jsonData?: any,
  navShowing: boolean,
}) {
  const data = useStaticQuery(graphql`
    query {
      nav: contentfulUi(contentful_id: { eq: "6eX13vamRU8O8boGpwxC24" }) {
        navigation {
          display
          url
          externalUrl
        }
      }
    }
  `);

  const renderData = (props && props.jsonData) || data;
  return (
    <>
      {renderData.nav.navigation.map((item: any, index: number) => {
        return (
          <div
            key={`MobileNavFloater${index}`}
            className={classNames({
              [styles.childLink]: true,
            })}
          >
            {item.url ? (
              <Link activeClassName={styles.buttonActive} to={`${item.url}/`}>
                {item.display}
              </Link>
            ) : (
              <a href={item.externalUrl} rel={`noreferrer`} target={"_blank"}>
                {item.display}
              </a>
            )}
          </div>
        );
      })}
    </>
  )
}

@observer
class Navigation extends React.Component<NavigationProps, any> {
  clicked() {
    this.props.ui.toggleNavShowing();
  }

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === "true" && window) {
      this.props.json.getData(JSON_NAV, {
        contentType: "ui",
        id: "6eX13vamRU8O8boGpwxC24",
      });
    }
    if (typeof window !== "undefined") {
      window.addEventListener('click', () => {
        if (this.props.ui.navShowing) {
          this.props.ui.toggleNavShowing();
        }
      })
    }
  }

  render() {
    const jsonData: any = this.props.json.store.get(JSON_NAV);

    return (
      <>
        <nav
          className={classNames({
            [styles.mobileWrapper]: true,
            [styles.active]: this.props.ui.navShowing,
          })}
          onClick={e => {
            e.stopPropagation();
            this.clicked();
          }}
        >
          <div
            className={styles.padding}
            style={{
              minHeight: `${this.props.ui.windowHeight}px`,
            }}
          >
            <DataWrapper
              jsonData={jsonData}
              navShowing={this.props.ui.navShowing}
            />
          </div>
        </nav>
      </>
    );
  }
}

export default Navigation;
