// @flow

import React from "react";
import { observer } from "mobx-react";
import { Link } from "gatsby";

import styles from "./style.module.scss";
import uiStore from "../../stores/ui-store";
import * as classNames from "classnames";

type LogoProps = {
  ui: uiStore,
};

@observer
class Logo extends React.Component<LogoProps, any> {
  clicked() {
    if (this.props.ui.navShowing) this.props.ui.toggleNavShowing();
    if (this.props.ui.storeActive) this.props.ui.setStoreActive(false);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={classNames({
            [styles.mixBlend]:
              this.props.ui.useMixBlend && !(this.props.ui.navShowing && this.props.ui.isMobileNav),
            [styles.wrapper]: true,
            [styles.isMap]: this.props.ui.isMap,
            [styles.isSoundSystem]:
              this.props.ui.isSoundSystem && !this.props.ui.navShowing,
            [styles.large]: this.props.ui.ageGateActive,
          })}
          onClick={() => this.clicked()}
        >
          <Link to={`/`}>
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 11">
              <path d="M3.1,4.7h0.8c0.6,0,1-0.1,1.2-0.3c0.2-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.3-0.8C4.9,2.6,4.5,2.5,4,2.5H3.1V4.7z M0,0.5 h5.1c1.1,0,1.9,0.3,2.5,0.8c0.6,0.5,0.8,1.3,0.8,2.3c0,1-0.3,1.8-0.9,2.3C6.9,6.4,6,6.7,4.8,6.7H3.1v3.7H0V0.5z" />{" "}
              <polygon points="29.1,0.5 37.3,0.5 37.3,2.6 32.1,2.6 32.1,4.2 36.9,4.2 36.9,6.2 32.1,6.2 32.1,8.1 37.4,8.1 37.4,10.4 29.1,10.4 " />{" "}
              <path d="M44.3,8.2h1.6c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.4,0.3-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.6-0.3-1.1-0.3h-1.6V8.2z M44.3,4.3h1.3c0.5,0,0.8-0.1,1-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.2-1-0.2h-1.4V4.3z M41.2,0.5 h5.7c1,0,1.7,0.2,2.2,0.7c0.5,0.5,0.8,1.1,0.8,1.8c0,0.6-0.2,1.1-0.5,1.5c-0.2,0.3-0.6,0.5-1.1,0.7C49,5.3,49.5,5.6,49.9,6 c0.3,0.4,0.5,0.9,0.5,1.6c0,0.5-0.1,1-0.4,1.4c-0.2,0.4-0.6,0.7-1,1c-0.3,0.1-0.6,0.3-1.2,0.3c-0.7,0.1-1.1,0.1-1.4,0.1h-5.3V0.5z" />{" "}
              <polygon points="51,0.5 59.2,0.5 59.2,2.6 54.1,2.6 54.1,4.2 58.8,4.2 58.8,6.2 54.1,6.2 54.1,8.1 59.3,8.1 59.3,10.4 51,10.4 	" />{" "}
              <path d="M66.2,6.6L65.1,3L64,6.6H66.2z M66.8,8.7h-3.5l-0.5,1.6h-3.1l3.7-9.9h3.3l3.7,9.9h-3.2L66.8,8.7z" />{" "}
              <polygon points="79.9,0.5 89.2,0.5 89.2,2.9 86.1,2.9 86.1,10.4 83,10.4 83,2.9 79.9,2.9 	" />{" "}
              <polygon points="89.2,0.5 92.6,0.5 94.6,3.8 96.6,0.5 100,0.5 96.1,6.2 96.1,10.4 93.1,10.4 93.1,6.2 	" />{" "}
              <path d="M13.8,10.5c-1.3,0-2.4-0.4-3.2-1C9.7,8.9,9.2,8,9.2,6.9V0.5h3.4v6.2c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.9,0.3 c0.4,0,0.6-0.1,0.9-0.3C14.9,7.4,15,7.1,15,6.7V0.5h3.4v6.4c0,1.1-0.5,2-1.3,2.7C16.2,10.2,15.1,10.5,13.8,10.5" />{" "}
              <path d="M74.7,10.5c-1.3,0-2.4-0.4-3.2-1c-0.9-0.7-1.3-1.5-1.3-2.7V0.5h3.4v6.2c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.9,0.3 c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.4,0.3-0.8V0.5h3.4v6.4c0,1.1-0.5,2-1.3,2.7C77.1,10.2,76,10.5,74.7,10.5" />{" "}
              <path d="M22.4,2.5h0.9c0.6,0,1,0.1,1.2,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.7,0.3-1.2,0.3h-0.8V2.5z M28.7,10.4l-3.2-3.8c0.6-0.1,1-0.4,1.4-0.7c0.6-0.6,0.9-1.3,0.9-2.3c0-1-0.3-1.7-0.8-2.3c-0.6-0.5-1.4-0.8-2.5-0.8h-5.1v9.9h3.1 V6.7h0l2.8,3.7H28.7z" />
            </svg>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Logo;
