// @flow

import {observable, action, computed} from 'mobx';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';

import type { Image } from '../components/BlurUpImage';

export class Order {
  purchase_units: Purchase[];
  payer: Payer;

  constructor(items: Purchase, payer: Payer) {
    this.purchase_units = [items];
    this.payer = payer;
  }
}

export class Money {
  currency_code = 'USD';
  value: string;

  constructor(value: string | number) {
    this.value = `${value}`;
  }
}

export class Amount extends Money {

  breakdown: Breakdown;

  constructor(value: string | number, breakdown?: Breakdown) {
    super(value);
    if (breakdown) this.breakdown = breakdown;
  }
}

export class Address {

  address_line_1: string;
  address_line_2: string;
  admin_area_2: string; // A city, town, or village.
  admin_area_1: string; // State, Format for postal delivery. For example, CA and not California
  postal_code: string;
  country_code: string;

  constructor(address_one: string, address_two: string, city: string, state: string, zip: string | number, country: string) {
    this.address_line_1 = address_one;
    this.address_line_2 = address_two;
    this.admin_area_1 = city;
    this.admin_area_2 = state;
    this.postal_code = `${zip}`;
    this.country_code = country;
  }
}

export class Name {
  fName: string;
  lName: string;

  constructor(fName: string, lName: string) {
    this.fName = fName;
    this.lName = lName;
  }

  get full() {
    return {
      full_name: `${this.fName} ${this.lName}`
    }
  }

  get value() {
    return {
      given_name: this.fName,
      surname: this.lName
    }
  }
}

export class Payer {

  name: any;
  email_address: string;
  address: Address;

  constructor(name: Name, email: string, address: Address) {
    this.name = name.value;
    this.email_address = email;
    this.address = address;
  }
}

export type Breakdown = {
  item_total: Money,
  shipping?: Money,
  tax_total?: Money,
  discount?: Money
}

export class Purchase {
  amount: Amount;
  items: any[];
  shipping: {
    name: any,
    address: Address
  };

  constructor(name: Name, address: Address, items: CartItem[], country: string) {
    this.shipping = {
      name: name.full,
      address: address
    };
    const total: number = reduce(items, (acc: number, item: CartItem) => {
      acc += Number(item.unit_amount.value);
      return acc;
    }, 0);
    this.items = reduce(items, (acc: any[], item: CartItem) => {
      acc.push(item.submit);
      return acc;
    }, []);
    let shipping: Money;
    if (country === 'US') {
      // if (total > 70) shipping = new Money(19);
      // else if (total > 61) shipping = new Money(10);
      // else if (total > 41) shipping = new Money(8);
      // else shipping = new Money(8);
      shipping = new Money(8 * items.length);
    } else {
      // if (total > 70) shipping = new Money(19);
      // else if (total > 61) shipping = new Money(19);
      // else if (total > 41) shipping = new Money(15);
      // else shipping = new Money(15);
      shipping = new Money(25 * items.length);
    }
    let breakdown: Breakdown = {
      item_total: new Money(total),
      shipping: shipping
    };
    this.amount = new Amount(total + Number(shipping.value), breakdown);
  }
}

export class CartItem {
  image: Image;
  name: string;
  unit_amount: Money;
  tax: Money;
  description: string;

  constructor(image: Image, name: string, unit_amount: Money, description: string='', tax?: Money) {
    this.image = image;
    this.name = name;
    this.unit_amount = unit_amount;
    this.description = description;
    this.tax = tax ? tax : new Money(0);
  }

  get submit() {
    return {
      name: this.name,
      unit_amount: this.unit_amount,
      tax: this.tax,
      quantity: `1`,
      description: this.description
    }
  }
}

class CartStore {

  @observable cart: CartItem[] = [];

  @action
  remove = (index: number): void => {
    this.cart = remove(this.cart, (item: CartItem, i: number) => {
      return index !== i;
    });
  };

  @action
  clear = (): void => {
    this.cart = [];
  };

  @action
  add = (item: CartItem): void => {
    this.cart.push(item);
  };

  @computed get numCart(): number {
    return this.cart.length;
  }

  @computed get total(): number {
    return reduce(this.cart, (total: number, item: CartItem) => {
      total += Number(item.unit_amount.value);
      return total;
    }, 0)
  }
}

export default CartStore;
