// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import styles from './style.module.scss';
import uiStore from '../../stores/ui-store';
// import * as classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

type AgeGateButtonProps = {
  ui: uiStore,
  location: any,
}

@observer
class AgeGateButton extends React.Component<AgeGateButtonProps, any> {

  clicked() {
    this.props.ui.setAgeGateActive(false);
    this.props.ui.setAgeGate();
    this.props.ui.bgToggle(this.props.location.pathname);
  }

  inputVal: string = '';

  @observable labelActive: boolean = true;

  @action
  setLabelActive = (active: boolean): void => {
    this.labelActive = active;
  };

  focused() {
    this.setLabelActive(false);
  }

  blur() {
    if (!this.inputVal) this.setLabelActive(true);
  }

  // input(val: string) {
  //   this.inputVal = val;
  //   this.setLabelActive(!val);
  // }

  render() {

    return (
      <div className={styles.container}>
        <CSSTransition
          appear={true}
          in={this.props.ui.ageGateActive}
          timeout={1000}
          classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            enterDone: styles.enterDone,
            exitActive: styles.exit,
            exitDone: styles.exitDone,
          }}
          unmountOnExit>
          <div className={styles.wrapper}>
            <div className={styles.innerWrapper}>
              <p>ARE YOU OVER THE AGE OF 21?</p>
              <div className={styles.buttons}>
                <div className={styles.button}
                     onClick={() => this.clicked()}>
                  YES
                </div>
                <div className={styles.button}>
                  NO
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default AgeGateButton;
