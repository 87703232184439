// @flow

import React from "react";
import { observer, inject } from "mobx-react";
import ReactResizeDetector from "react-resize-detector";

import { STORE_UI, STORE_JSON, STORE_CART } from "../constants/stores";
import UiStore from "../stores/ui-store";
import CartStore from "../stores/cart-store";
import JsonStore from "../stores/json-store";
import Background from "../components/Background";
import Shop from "../components/ThirdPartyShop";
import AgeGate from "../components/AgeGate";
import NavButton from "../components/NavButton";
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import { registerPassiveEvent } from "../utils/passive-scroll";
import ShopButton from "../components/ShopButton";

type LayoutProps = {
  ui: UiStore,
  json: JsonStore,
  cart: CartStore,
  children: any,
  location: {
    pathname: string,
  },
};

@inject(STORE_UI, STORE_JSON, STORE_CART)
@observer
class Layout extends React.Component<LayoutProps, any> {
  handleResize = (width: number) => {
    if (width !== 0) this.props[STORE_UI].setWindowWidth(width);
  };

  handleHeightResize = (width: number, height: number) => {
    if (height !== 0) this.props[STORE_UI].setWindowHeight(height);
  };

  componentDidMount() {
    if (window) {
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line no-inner-declarations
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
      window.gtag("config", "UA-166299382-1");
      registerPassiveEvent("touchstart", document.body, () => {});
    }
  }

  render() {
    return (
      <>
        <Background ui={this.props.ui} json={this.props.json} index={0} />
        <Background ui={this.props.ui} json={this.props.json} index={1} />
        {/* <ShopButton location={this.props.location} ui={this.props.ui} /> */}
        <Shop location={this.props.location} ui={this.props.ui} />
        <AgeGate ui={this.props.ui} location={this.props.location} />
        <Logo ui={this.props.ui} />
        <NavButton ui={this.props.ui} cart={this.props.cart} />
        <Navigation ui={this.props.ui} json={this.props.json} />
        <div id={`body`}>{this.props.children}</div>
        <ReactResizeDetector handleWidth onResize={this.handleResize} />
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -100,
          }}
        >
          <ReactResizeDetector
            handleHeight
            onResize={this.handleHeightResize}
          />
        </div>
      </>
    );
  }
}

export default Layout;
