// @flow

import React from "react";
import { observer } from "mobx-react";
import { Link } from "gatsby";

import styles from "./style.module.scss";
import uiStore from "../../stores/ui-store";
import CartStore from "../../stores/cart-store";
import * as classNames from "classnames";

type NavButtonProps = {
  ui: uiStore,
  cart: CartStore,
};

@observer
class NavButton extends React.Component<NavButtonProps, any> {
  clicked() {
    if (this.props.ui.storeActive) this.props.ui.setStoreActive(false);
    else this.props.ui.toggleNavShowing();
  }

  cartClicked() {
    if (this.props.ui.navShowing) this.props.ui.toggleNavShowing();
  }

  mapClicked() {
    this.props.ui.setMapNavActive(!this.props.ui.mapNavActive);
  }

  render() {
    return (
      <>
        <div
          className={classNames({
            [styles.mixBlend]:
              this.props.ui.useMixBlend && !this.props.ui.navShowing,
            [styles.wrapper]: true,
            [styles.disabled]: this.props.ui.ageGateActive,
            [styles.active]:
              this.props.ui.navShowing || this.props.ui.storeActive,
            [styles.isMap]: this.props.ui.isMap,
          })}
          onClick={e => {
            e.stopPropagation();
            this.clicked()
          }}
        >
          <div className={styles.hamburger}>
            <svg viewBox="0 0 1000 1000" id="burger" width="100%" height="100%">
              <path d="M0,201.4h1000v39.9H0V201.4z M0,480h1000V520H0V480z M0,758.7h1000v39.9H0V758.7z" />
            </svg>
          </div>
          <div className={styles.close}>
            <svg viewBox="0 0 100 100" id="close" width="100%" height="100%">
              <g>
                <g>
                  <rect
                    x="-19"
                    y="48.2"
                    transform="matrix(0.7071 0.7071 -0.7071 0.7071 50.001 -20.7082)"
                    width="137.9"
                    height="3.5"
                  />
                </g>
                <g>
                  <rect
                    x="48.2"
                    y="-19"
                    transform="matrix(0.7071 0.7071 -0.7071 0.7071 49.999 -20.7131)"
                    width="3.5"
                    height="137.9"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div
          className={classNames({
            [styles.cartWrapper]: true,
            [styles.disabled]: this.props.ui.ageGateActive,
            [styles.active]: this.props.cart.numCart > 0,
            [styles.isMap]: this.props.ui.isMap,
          })}
          onClick={() => this.cartClicked()}
        >
          <div className={styles.inner}>
            <Link to={`checkout/`}>{`CART (${this.props.cart.numCart})`}</Link>
          </div>
        </div>
        <div
          className={classNames({
            [styles.mapWrapper]: true,
            [styles.disabled]: this.props.ui.ageGateActive,
            [styles.active]: this.props.ui.isMap,
            [styles.navActive]: this.props.ui.navShowing,
          })}
          onClick={() => this.mapClicked()}
        >
          <svg x="0px" y="0px" viewBox="0 0 38 35">
            <rect x="11.5" y="26.6" width="25" height="1.4" />
            <rect x="1.5" y="26.6" width="7.6" height="1.4" />
            <rect x="1.5" y="16.8" width="7.6" height="1.4" />
            <rect x="1.5" y="7" width="7.6" height="1.4" />
            <rect x="11.5" y="16.8" width="25" height="1.4" />
            <rect x="11.5" y="7" width="25" height="1.4" />
          </svg>
        </div>
      </>
    );
  }
}

export default NavButton;
