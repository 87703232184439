// @flow

import { observable, action, computed } from "mobx";
// import throttle from 'lodash/throttle';

import { toggleLock } from "../utils/lock-scroll";

let initCount: number = 0;

const getPageName = (page: string): string => {
  if (page === "/") {
    return "home";
  } else {
    return page.replace(/\//g, "");
  }
};

const MOBILE_BREAKPOINT: number = 500;
const MOBILE_NAV_BREAKPOINT: number = 1100;
const MOBILE_BREAKPOINT_ANIMATED: number = 1020;

class UiStore {
  isBrowser: boolean = false;

  initForBrowserUse(): void {
    this.isBrowser = true;
    let y: number = 0;
    let ticking: boolean = false;

    window.addEventListener("scroll", () => {
      y = window.scrollY || window.pageYOffset;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (initCount < 5 && this.windowHeight > 0) {
            this.setInitialScrolled(y);
            initCount++;
          }
          this.setScrollPos(y);
          ticking = false;
        });
        ticking = true;
      }
    });
  }

  markerClickRefs: any = {};

  setMarkerClickRef = (id: string, ref: any): void => {
    this.markerClickRefs[id] = ref;
  };

  @observable longitude: number = 0;
  @observable latitude: number = 0;

  @action
  setLongitude = (longitude: number): void => {
    this.longitude = longitude;
  };

  @action
  setLatitude = (latitude: number): void => {
    this.latitude = latitude;
  };

  @action
  setMapNavActive = (active: boolean): void => {
    this.mapNavActive = active;
  };

  @observable mapNavActive: boolean = true;

  @action
  setAgeGateActive = (active: boolean): void => {
    this.ageGateActive = active;
  };

  @observable ageGateActive: boolean = false;

  @action
  setStoreActive = (active: boolean): void => {
    this.storeActive = active;
  };

  @observable storeActive: boolean = false;

  AGE_GATE_KEY: string = "pb_agegate";

  @action
  checkAgeGate = (): void => {
    if (!window.localStorage.getItem(this.AGE_GATE_KEY)) {
      this.bgToggle("ageGate");
      this.setAgeGateActive(true);
    }
  };

  @action
  setAgeGate = (): void => {
    if (!window.localStorage.getItem(this.AGE_GATE_KEY)) {
      window.localStorage.setItem(this.AGE_GATE_KEY, `yo wasup dawg`);
      this.setAgeGateActive(false);
    }
  };

  @observable activeBgIndex: number = 0;

  @action
  setActiveBgIndex = (index: number): void => {
    this.activeBgIndex = index;
  };

  @observable bgPaths: string[] = ["", ""];

  @observable currentPage: string = "";

  @computed get isSoundSystem(): boolean {
    return this.currentPage === "soundsystem";
  }

  @computed get useMixBlend(): boolean {
    return ["drink", "home", "infused"].includes(this.currentPage) && !this.ageGateActive;
  }

  @computed get isMap(): boolean {
    return this.currentPage === "stores";
  }

  @computed get isCheckout(): boolean {
    return this.currentPage === "checkout";
  }

  @action
  bgToggle = (path: string): void => {
    const pageName = getPageName(path);
    this.currentPage = pageName;
    const index: number = this.activeBgIndex === 0 ? 1 : 0;
    this.bgPaths[index] = pageName;
    this.activeBgIndex = index;
  };

  @observable initialScrolled: boolean = false;

  @action
  setInitialScrolled = (y: number): void => {
    this.initialScrolled = y >= this.windowHeight;
  };

  @observable navShowing: boolean = false;

  @action
  toggleNavShowing = (): void => {
    this.navShowing = !this.navShowing;
    if (this.navShowing) {
      if (this.isMobileNav) this.initFullscreenOverlay();
    } else this.closeFullscreenOverlay();
  };

  @observable shopShowing: boolean = true;

  @action
  toggleShopShowing = (): void => {
    this.shopShowing = !this.shopShowing;
  };

  @observable windowWidth: number = 0;
  @observable windowHeight: number = 0;

  @action
  setWindowWidth = (x: number): void => {
    this.windowWidth = x;
  };

  @action
  setWindowHeight = (y: number): void => {
    this.windowHeight = y;
  };

  initFullscreenOverlay(): void {
    const body: any = document.getElementById("body");
    this.cachedScrollY = this.windowScrollY;
    toggleLock(true);
    body.style.transform = `translateY(-${this.windowScrollY}px)`;
  }

  closeFullscreenOverlay(): void {
    const body: any = document.getElementById("body");
    toggleLock(false);
    body.style.transform = "";
    if (this.cachedScrollY !== 0) {
      setTimeout(() => {
        window.scrollTo(0, this.cachedScrollY);
        this.cachedScrollY = 0;
      }, 1);
    }
  }

  @observable windowScrollY: number = 0;
  cachedScrollY: number = 0;

  @computed get isScrolled(): boolean {
    return this.windowScrollY > 0;
  }

  @computed get isMobile(): boolean {
    if (!this.isBrowser || this.windowWidth === 0) return false;
    return this.windowWidth <= MOBILE_BREAKPOINT;
  }

  @computed get isMobileNav(): boolean {
    if (!this.isBrowser || this.windowWidth === 0) return false;
    return this.windowWidth <= MOBILE_NAV_BREAKPOINT;
  }

  @computed get isMobileAnimated(): boolean {
    if (!this.isBrowser || this.windowWidth === 0) return false;
    return this.windowWidth <= MOBILE_BREAKPOINT_ANIMATED;
  }

  @action
  setScrollPos = (y: number): void => {
    this.windowScrollY = y;
  };
}

export default UiStore;
