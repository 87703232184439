let supportsPassive = false;

try {
  const opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true;
      return '';
    }
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {
  console.error(e);
}
export function registerPassiveEvent(event, el, fn) {
  el.addEventListener(event, fn, supportsPassive ? { passive: true } : false);
}

export function removePassiveEvent(event, el, fn) {
  el.removeEventListener(event, fn, supportsPassive ? { passive: true } : false);
}