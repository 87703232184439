// @flow

import React from 'react';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import * as classNames from 'classnames';

import styles from './style.module.scss';

export type Image = {
  file: {
    url: string,
    details: {
      image: {
        width: number,
        height: number,
      }
    }
  },
}

type BlurUpImageProps = {
  backgroundPosition?: string,
  contain?: boolean,
  borderRadius?: boolean,
  image: Image
}

@observer
class BlurUpImage extends React.Component<BlurUpImageProps, any> {

  ref: any;
  observer: any;

  constructor(props: BlurUpImageProps) {
    super(props);
    this.ref = React.createRef();
  }

  @observable loaded: boolean = false;

  @action
  setLoaded = (loaded: boolean): void => {
    this.loaded = loaded;
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(changes => {
      for (const change of changes) {
        if (change.isIntersecting) this.setLoaded(true);
      }
    }, {});
    this.observer.USE_MUTATION_OBSERVER = false;
    this.setRefLoop();
  }

  setRefLoop() {
    if (this.ref.current) {
      this.observer.observe(this.ref.current);
    } else setTimeout(() => this.setRefLoop(), 60);
  }

  render() {

    const wrapperClass = classNames({
      [styles.wrapper]: true,
      [styles.borderRadius]: this.props.borderRadius,
    });

    const blurClass = classNames({
      [styles.container]: true,
      [styles.blur]: true,
      [styles.contain]: this.props.contain,
    });

    const loadClass = classNames({
      [styles.container]: true,
      [styles.load]: true,
      [styles.loaded]: this.loaded,
      [styles.contain]: this.props.contain,
    });

    return (
      <div className={wrapperClass}>
        <div className={blurClass}
             ref={this.ref}
             style={{
               backgroundPosition: this.props.backgroundPosition ? this.props.backgroundPosition : 'center center',
               backgroundImage: `url(${this.props.image.file.url}?w=100&fl=progressive&q=50)`
             }}>

        </div>
        <div className={loadClass}
             style={{
               backgroundPosition: this.props.backgroundPosition ? this.props.backgroundPosition : 'center center',
               backgroundImage: `url(${this.loaded ? this.props.image.file.url : ''})`
             }}>

        </div>
      </div>
    )
  }
}

export default BlurUpImage;
