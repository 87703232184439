// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-checkout-js": () => import("/opt/build/repo/src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-soundsystem-js": () => import("/opt/build/repo/src/pages/soundsystem.js" /* webpackChunkName: "component---src-pages-soundsystem-js" */),
  "component---src-pages-stores-js": () => import("/opt/build/repo/src/pages/stores.js" /* webpackChunkName: "component---src-pages-stores-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

