// @flow

import React from "react";
import { observer } from "mobx-react";
import { computed, reaction } from "mobx";
import forEach from "lodash/forEach";

import styles from "./style.module.scss";
import uiStore from "../../stores/ui-store";
import jsonStore from "../../stores/json-store";
import type { Image } from "../BlurUpImage";
import { JSON_HOME, JSON_STORES } from "../../constants/json";
import BlurUpImage from "../BlurUpImage";
import * as classNames from "classnames";
import { STORE_JSON } from "../../constants/stores";
import parseLongText from "../../utils/parse-long-text";

type BackgroundProps = {
  ui: uiStore,
  json: jsonStore,
  index: number,
};

@observer
class Background extends React.Component<BackgroundProps, any> {
  ref: any;
  map: any;
  service: any;
  positionInit: boolean = false;
  actionInitiated: boolean = false;

  constructor(props: BackgroundProps) {
    super(props);
    this.ref = React.createRef();

    reaction(
      () => this.content,
      () => {
        if (this.content.map) {
          this.initMap();
        }
      }
    );
  }

  onClick() {
    this.props.ui.setMapNavActive(false);
  }

  initMap() {
    try {
      if (this.ref.current && window && window.google) {
        this.map = new window.google.maps.Map(this.ref.current, {
          center: {
            lat: this.props.ui.latitude || 34.053813,
            lng: this.props.ui.longitude || -118.246158,
          },
          zoom: 13,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: this.props.ui.windowWidth >= 600,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
          },
          streetViewControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
          },
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#040405",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#040405",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#040405",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#040405",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#040405",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e0e0e0",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#2c2c2c",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#06ff00",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#06ff00",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#06ff00",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#040405",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
        });
        this.service = new window.google.maps.InfoWindow();
        const data: any = this.props[STORE_JSON].store.get(JSON_STORES);
        let numStore: number = 0;
        forEach(data.stores.locations, (data: any, outerIndex: number) => {
          forEach(data.locations, (location: any, index: number) => {
            // const number: number = index + 1;
            numStore++;
            if (location.location) {
              const marker: any = new window.google.maps.Marker({
                map: this.map,
                draggable: false,
                position: {
                  lat: location.location.lat,
                  lng: location.location.lon,
                },
                label: `${numStore >= 10 ? "" : "0"}${numStore}`,
                icon: {
                  url: `https://images.ctfassets.net/6xh2c92820gv/2Z6I6pQza0nFSBWEIqIbM9/d89c1999421df1ddde0c4a8310df0f4e/pointer.svg`,
                  scaledSize: new window.google.maps.Size(32, 32),
                },
              });
              const id: string = `${outerIndex}${index}`;
              this.props.ui.setMarkerClickRef(id, () => {
                this.actionInitiated = true;
                this.map.setZoom(15);
                this.map.panTo({
                  lat: location.location.lat,
                  lng: location.location.lon,
                });
                this.service.setContent(`<div class="global-link">
                    <a href=${location.url}
                    target="_blank" 
                    rel="noopener noreferrer">${location.name}</a><br>
                    ${parseLongText(location.address)}
                </div>`);
                this.service.open(this.map, marker);
              });
              marker.addListener("click", this.props.ui.markerClickRefs[id]);
            }
          });
        });
        if (!this.props.ui.latitude || !this.props.ui.longitude) {
          window.navigator.geolocation.getCurrentPosition(
            (position: {
              coords: {
                longitude: number,
                latitude: number,
              },
            }) => {
              if (position && position.coords && !this.positionInit) {
                this.positionInit = true;
                if (!this.actionInitiated) {
                  this.map.panTo({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                }
                this.props.ui.setLatitude(position.coords.latitude);
                this.props.ui.setLongitude(position.coords.longitude);
              }
            }
          );
        }
      } else {
        setTimeout(() => {
          this.initMap();
        }, 100);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @computed get isActive(): boolean {
    return this.props.ui.activeBgIndex === this.props.index;
  }

  @computed get zIndexUp(): boolean {
    const path: string = this.props.ui.bgPaths[this.props.index];
    return this.props.ui.ageGateActive && path === "ageGate";
  }

  @computed get content(): {
    overlayColor?: string,
    image?: Image,
    color?: string,
    map?: boolean,
  } {
    const path: string = this.props.ui.bgPaths[this.props.index];
    if (path) {
      if (path === JSON_HOME) {
        const data = this.props.json.store.get(JSON_HOME);
        if (data) return data.home.homepageBackground;
      } else if (path === "ageGate") {
        const data = this.props.json.store.get("gate");
        if (data) {
          return data.gate.ageGateBackgrounds[0];
        }
      } else if (path === "stores") {
        return {
          map: true,
        };
      } else {
        const data = this.props.json.store.get(path);
        if (data) return data.page.background;
      }
    }
    return {};
  }

  render() {
    return (
      <div
        className={classNames({
          [styles.isMap]: !!this.content.map,
          [styles.wrapper]: true,
          [styles.zIndexUp]: this.zIndexUp,
          [styles.active]: this.isActive,
        })}
      >
        <div
          ref={this.ref}
          onClick={() => this.onClick()}
          className={styles.map}
          style={{
            display: this.content.map ? "block" : "none",
          }}
        />
        {this.content.color && (
          <div
            className={styles.color}
            style={{ background: this.content.color }}
          />
        )}
        {this.content.image && (
          <div
            className={classNames({
              [styles.imageWrapper]: !this.content.noTransition,
              [styles.imageWrapperNoTransition]: this.content.noTransition,
            })}
          >
            <BlurUpImage image={this.content.image} />
          </div>
        )}
        {this.content.overlayColor && (
          <div
            className={styles.overlay}
            style={{ background: this.content.overlayColor }}
          />
        )}
      </div>
    );
  }
}

export default Background;
