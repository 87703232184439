// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import styles from './style.module.scss';
import uiStore from '../../stores/ui-store';
import * as classNames from 'classnames';

type AgeGateButtonProps = {
  ui: uiStore,
  location: any,
}

@observer
class AgeGateButton extends React.Component<AgeGateButtonProps, any> {

  clicked() {
    this.props.ui.setAgeGateActive(false);
  }

  @observable labelActive: boolean = true;

  @action
  setLabelActive = (active: boolean): void => {
    this.labelActive = active;
  };

  render() {

    return <div className={classNames({
      [styles.container]: true,
      [styles.active]: this.props.ui.storeActive,
    })}>
      <div className={styles.wrapper} id="jane-frame-script">

      </div>
    </div>
  }
}

export default AgeGateButton;
